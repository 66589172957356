<template>
  <b-card
    class="company-table-custom"
    no-body
  >
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="font-weight-bolder">
          Danh sách sản phẩm bán chạy kênh
        </b-card-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <b-table
        v-if="tableData"
        :items="tableData"
        responsive
        :fields="fields"
        class="mb-0"
      >

        <template #head()="{ label, field: { key }}">
          <img
            v-if="key == 'website'"
            src="/website.png"
            alt="website"
            width="15"
            class="head-img-icon"
          >
          <img
            v-if="key == 'shopee'"
            src="/shopee.png"
            alt="shopee"
            width="15"
            class="head-img-icon"
          >
          <img
            v-if="key == 'tiki'"
            src="/tiki.png"
            alt="tiki"
            width="15"
            class="head-img-icon"
          >
          <img
            v-if="key == 'lazada'"
            src="/lazada.png"
            alt="lazada"
            width="15"
            class="head-img-icon"
          >
          <img
            v-if="key == 'tiktok'"
            src="/tiktok.png"
            alt="tiktok"
            width="15"
            class="head-img-icon"
          >
          <span>{{ label }}</span>
        </template>
        <!-- company -->
        <template #cell(company)="data">
          <div class="d-flex align-items-center">
            <b-avatar
              rounded
              size="32"
              variant="light-company"
              class="mr-1"
            >
              <b-img
                :src="data.item.avatarImg"
                alt="avatar img"
              /></b-avatar>
            <div>
              <div class="font-weight-bolder">
                {{ data.item.title }}
              </div>
              <div class="font-small-2 text-muted">
                {{ data.item.subtitle }}
              </div>
            </div>
          </div>
        </template>

        <!-- website -->
        <template #cell(website)="data">
          <b>{{ data.item.website }}</b>
          <span class="font-small-2 text-muted"> Đơn</span>
        </template>

        <!-- shopee -->
        <template #cell(shopee)="data">
          <b>{{ data.item.shopee }}</b>
          <span class="font-small-2 text-muted"> Đơn</span>
        </template>

        <!-- tiki -->
        <template #cell(tiki)="data">
          <b>{{ data.item.tiki }}</b>
          <span class="font-small-2 text-muted"> Đơn</span>
        </template>

        <!-- lazada -->
        <template #cell(lazada)="data">
          <b>{{ data.item.lazada }}</b>
          <span class="font-small-2 text-muted"> Đơn</span>
        </template>

        <!-- tiktok -->
        <template #cell(tiktok)="data">
          <b>{{ data.item.tiktok }}</b>
          <span class="font-small-2 text-muted"> Đơn</span>
        </template>
      </b-table>
    </b-card-body>
  </b-card>
  <!-- <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
  </b-card> -->
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
  BCard, BTable, BAvatar, BImg, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    flatPickr,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'stt', label: 'STT' },
        { key: 'company', label: 'SẢN PHẨM' },
        { key: 'website', label: 'WEBSITE' },
        { key: 'shopee', label: 'SHOPEE' },
        { key: 'tiki', label: 'TIKI' },
        { key: 'lazada', label: 'LAZADA' },
        { key: 'tiktok', label: 'TIKTOK' },
      ],
      rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
.head-img-icon {
  margin-top: -3px;
  margin-right: 4px;
}
</style>
