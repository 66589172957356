<template>
  <b-row class="match-height channels-main">
    <b-col md="6">
      <apex-bar-chart />
    </b-col>
    <b-col md="6">
      <ApexPieChart />
    </b-col>
    <b-col md="12">
      <ecommerce-company-table :table-data="companyTable" />
    </b-col>
    <b-col md="6">
      <ApexColumnChart />
    </b-col>
    <b-col md="6">
      <ApexPieChart />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ApexBarChart from './dashboard/ApexBarChart.vue'
import ApexPieChart from './dashboard/ApexPieChart.vue'
import ApexColumnChart from './dashboard/ApexColumnChart.vue'
import EcommerceCompanyTable from './dashboard/EcommerceCompanyTable.vue'

export default {
  components: {
    BRow,
    BCol,
    ApexBarChart,
    ApexPieChart,
    ApexColumnChart,
    EcommerceCompanyTable,
  },

  data() {
    return {
      companyTable: [
        {
          stt: 1,
          avatarImg: '/clother1.jpeg',
          title: 'Áo Polo Lucas Chất Vải Cotton CVC Màu Be Phom SlimFit',
          subtitle: 'Sku sản phẩm',
          avatarIcon: 'MonitorIcon',
          avatarColor: 'light-primary',
          avatarTitle: 'Technology',
          website: Math.floor(Math.random() * 2022),
          shopee: Math.floor(Math.random() * 2022),
          tiki: Math.floor(Math.random() * 2022),
          lazada: Math.floor(Math.random() * 2022),
          tiktok: Math.floor(Math.random() * 2022),
          loss: true,
        },
        {
          stt: 2,
          avatarImg: '/clother2.jpeg',
          title: 'Áo Polo Lucas Chất Vải Cotton CVC Màu Đỏ Phom Slimfit',
          subtitle: 'Sku sản phẩm',
          avatarIcon: 'CoffeeIcon',
          avatarColor: 'light-success',
          avatarTitle: 'Grocery',
          website: Math.floor(Math.random() * 2022),
          shopee: Math.floor(Math.random() * 2022),
          tiki: Math.floor(Math.random() * 2022),
          lazada: Math.floor(Math.random() * 2022),
          tiktok: Math.floor(Math.random() * 2022),
          loss: false,
        },
        {
          stt: 3,
          avatarImg: '/clother3.jpeg',
          title: 'Áo Polo Lucas Chất Vải Cotton CVC Màu Blue Gray Phom Slimfit',
          subtitle: 'Sku sản phẩm',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          website: Math.floor(Math.random() * 2022),
          shopee: Math.floor(Math.random() * 2022),
          tiki: Math.floor(Math.random() * 2022),
          lazada: Math.floor(Math.random() * 2022),
          tiktok: Math.floor(Math.random() * 2022),
          loss: false,
        },
        {
          stt: 4,
          avatarImg: '/clother4.jpeg',
          title: 'Áo Polo Lucas Chất Vải Cotton CVC Màu Hồng Phom SlimFit',
          subtitle: 'Sku sản phẩm',
          avatarIcon: 'MonitorIcon',
          avatarColor: 'light-primary',
          website: Math.floor(Math.random() * 2022),
          shopee: Math.floor(Math.random() * 2022),
          tiki: Math.floor(Math.random() * 2022),
          lazada: Math.floor(Math.random() * 2022),
          tiktok: Math.floor(Math.random() * 2022),
          loss: false,
        },
        {
          stt: 5,
          avatarImg: '/clother5.jpeg',
          title: 'Áo Polo Lucas Chất Vải Cotton CVC Màu Xanh Lá Phom SlimFit',
          subtitle: 'Sku sản phẩm',
          avatarIcon: 'CoffeeIcon',
          avatarColor: 'light-success',
          website: Math.floor(Math.random() * 2022),
          shopee: Math.floor(Math.random() * 2022),
          tiki: Math.floor(Math.random() * 2022),
          lazada: Math.floor(Math.random() * 2022),
          tiktok: Math.floor(Math.random() * 2022),
          loss: true,
        },
        {
          stt: 6,
          avatarImg: '/clother6.jpeg',
          title: 'Áo Polo Lucas Chất Vải Cotton CVC Màu Xanh Navi Phom SlimFit',
          subtitle: 'Sku sản phẩm',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          website: Math.floor(Math.random() * 2022),
          shopee: Math.floor(Math.random() * 2022),
          tiki: Math.floor(Math.random() * 2022),
          lazada: Math.floor(Math.random() * 2022),
          tiktok: Math.floor(Math.random() * 2022),
          loss: false,
        },
        {
          stt: 7,
          avatarImg: '/clother7.jpeg',
          title: 'Áo Polo Alan Chất Vải Cotton CVC Trắng Xanh Navi Phom SlimFit',
          subtitle: 'Sku sản phẩm',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          website: Math.floor(Math.random() * 2022),
          shopee: Math.floor(Math.random() * 2022),
          tiki: Math.floor(Math.random() * 2022),
          lazada: Math.floor(Math.random() * 2022),
          tiktok: Math.floor(Math.random() * 2022),
          loss: false,
        },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
.channels-main{
  .company-table-custom .table th{
    white-space: nowrap;
  }
  .b-avatar-custom{
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
