<template>
  <b-card>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="font-weight-bolder">
          Tỷ lệ hủy đơn
        </b-card-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="350"
        :options="apexChatData.columnChart.chartOptions"
        :series="apexChatData.columnChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
  BCard, BCardTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    flatPickr,
  },
  data() {
    return {
      apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
}
</script>
