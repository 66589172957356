<template>
  <b-card>
    <b-card-title class="mb-1">
      Biểu đồ
    </b-card-title>
    <vue-apex-charts
      type="pie"
      height="350"
      :options="apexChatData.pieChart.chartOptions"
      :series="apexChatData.pieChart.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
  },
  data() {
    return {
      apexChatData,
    }
  },
}
</script>
